.table {
  th,
  td {
    padding: 10px 25px;
  }

  th {
    text-align: left;
  }

  td {
    vertical-align: top;

    ul {
      margin: 0;
    }
  }
}
