.DropArea {
  display: flex;
  text-align: center;
  justify-content: center;
  min-width: 322px;
  max-width: 600px;
  height: 100px;
  border: dashed 2px #b6b6b6;
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  flex-grow: 0;
}

.UploaderWrapper {
  border-radius: 5px;
  background-color: rgb(202, 202, 202);
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
